<template>
  <div class="rch-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img
            lazy-src="../assets/nonet.png"
            width="200"
            max-width="250"
            src="../assets/nonet.png"
            cols="12"
          ></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card>
        <v-card-title class="primary--text">
          <h3>
            Employees Running Log

            <v-select
              v-model="seletedView"
              :items="views"
              item-text="text"
              item-value="value"
              solo
              dense
              class="mt-5"
              @change="changeView"
            ></v-select>
          </h3>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-row class="px-5 mt-5" v-if="user.role == 1 || user.role == 4">
          <v-spacer></v-spacer>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="vehicle_no"
                  :items="vehicles"
                  dense
                  filled
                  label="Vehicle No"
                  @change="search = vehicle_no"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-dialog
                  ref="dialog"
                  v-model="date_modal"
                  :return-value.sync="dates"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Date range"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      filled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_modal = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="filterDate"> OK </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="show_history"
          :search="search"
          @current-items="getFiltered"
        >
          <template v-slot:item.is_fuel="{ item }">
            <div v-if="parseInt(item.is_fuel)">Yes</div>
            <div v-else>No</div>
          </template>
          <template v-slot:item.is_oil="{ item }">
            <div v-if="parseInt(item.is_oil)">Yes</div>
            <div v-else>No</div>
          </template>
          <template v-slot:item.approved="{ item }">
            <span
              v-if="parseInt(item.approved)"
              class="teal lighten-5 rounded-circle"
            >
              <v-icon small color="green darken-2">
                mdi-check-circle-outline
              </v-icon>
            </span>
            <span v-else class="pink lighten-5 rounded-circle">
              <v-icon small color="red darken-2"> mdi-timer-sand-empty </v-icon>
            </span>
          </template>
          <template v-slot:item.edit_request="{ item }">
            <div v-if="parseInt(item.edit_request)">Yes</div>
            <div v-else>No</div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              text
              color="primary"
              class="mx-0 px-0"
              dark
              @click="more(item)"
            >
              More
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-btn class="mb-3 ml-3 mt-3" v-if="user.role == 1 || user.role == 4">
        <export-excel
          :data="excel_history"
          :fields="excel_headders"
          :name="excel_name"
        >
          Download Excel
        </export-excel>
      </v-btn>
      <v-dialog v-model="detail_log" scrollable max-width="500px">
        <v-card v-if="detail_history">
          <v-card-title>Runnninng log : {{ detail_history.id }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 100%">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>{{ detail_history.date }}</td>
                  </tr>
                  <tr>
                    <td>Employee</td>
                    <td>{{ detail_history.user_name }}</td>
                  </tr>

                  <tr>
                    <td>Vehicle Number</td>
                    <td>{{ detail_history.vehicle_no }}</td>
                  </tr>
                  <tr>
                    <td>Time Out</td>
                    <td>{{ detail_history.time_out }}</td>
                  </tr>
                  <tr>
                    <td>Time In</td>
                    <td>{{ detail_history.time_in }}</td>
                  </tr>
                  <tr>
                    <td>Meter Reading Out</td>
                    <td>{{ detail_history.meter_out }}</td>
                  </tr>
                  <tr>
                    <td>Meter Reading In</td>
                    <td>{{ detail_history.meter_in }}</td>
                  </tr>
                  <tr>
                    <td>No of Km</td>
                    <td>{{ detail_history.no_of_km }}</td>
                  </tr>
                  <tr>
                    <td>Project code</td>
                    <td>{{ detail_history.project_code }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ detail_history.description }}</td>
                  </tr>
                  <tr>
                    <td>Image</td>
                    <td><img :src="detail_history.image" width="100%" /></td>
                  </tr>
                  <tr>
                    <td>Daily Check List</td>
                    <td v-if="detail_history.daily_chek_list">
                      <span
                        v-for="check in detail_history.daily_chek_list"
                        :key="check"
                      >
                        {{ check }},
                      </span>
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Daily Check Defects</td>
                    <td v-if="detail_history.defets_if_any">
                      {{ detail_history.defets_if_any }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Daily Check Others</td>
                    <td v-if="detail_history.other_checks">
                      {{ detail_history.other_checks }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel</td>
                    <td v-if="detail_history.fuel">
                      {{ detail_history.fuel }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>No of Litres</td>
                    <td v-if="detail_history.fuel_no_of_liters">
                      {{ detail_history.fuel_no_of_liters }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Meter Reading (Present)</td>
                    <td v-if="detail_history.fuel_meter_present">
                      {{ detail_history.fuel_meter_present }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Meter Reading at Last filling</td>
                    <td v-if="detail_history.fuel_meter_last">
                      {{ detail_history.fuel_meter_last }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>No of KMS since last filling</td>
                    <td v-if="detail_history.fuel_no_of_kms">
                      {{ detail_history.fuel_no_of_kms }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>KMs per Liter</td>
                    <td v-if="detail_history.fuel_kms_per_liter">
                      {{ detail_history.fuel_kms_per_liter }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel Amount</td>
                    <td v-if="detail_history.fuel_amount">
                      {{ detail_history.fuel_amount }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Oil No of Litres</td>
                    <td v-if="detail_history.oil_no_of_liters">
                      {{ detail_history.oil_no_of_liters }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Oil Meter Reading</td>
                    <td v-if="detail_history.oil_meter_reading">
                      {{ detail_history.oil_meter_reading }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel Amount</td>
                    <td v-if="detail_history.oil_amount">
                      {{ detail_history.oil_amount }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Approved by</td>
                    <td v-if="detail_history.approved_by">
                      {{ detail_history.approved_by }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="detail_log = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="approve(detail_history.id)"
              v-if="parseInt(detail_history.open_for_edit) && seletedView == 2"
            >
              Approve
            </v-btn>

            <v-btn
              color="blue darken-1"
              text
              @click="openEdit(detail_history.id)"
              v-else-if="
                parseInt(detail_history.edit_request) && seletedView == 2
              "
            >
              Open For Edit
            </v-btn>
            <!-- <v-btn
              color="blue darken-1"
              text
              @click="cancelApprove(detail_history.id)"
              v-else-if="detail_history.approved"
            >
              Cancel Approve
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Dashboard",
  components: {
    // TabBar,
  },
  data() {
    return {
      excel_name:
        "LogHistory" +
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10) +
        ".xls",
      seletedView: 1,
      views: [{ text: "All Employees", value: 1 }],
      nonet: false,
      detail_log: false,
      detail_history: null,
      vehicles: [],
      vehicle_no: null,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      date_modal: false,
      search: "",
      headers: [
        { text: "Date", value: "date", align: "start" },
        { text: "Vehicle Number", value: "vehicle_id" },
        { text: "Employee", value: "user_id" },
        { text: "Project", value: "project_id" },
        { text: "Time Out", value: "time_out" },
        { text: "Time In", value: "time_in" },
        { text: "Meter reading Out", value: "meter_out" },
        { text: "Meter reading In", value: "meter_in" },
        { text: "No of km", value: "no_of_km" },
        { text: "Fuel", value: "is_fuel" },
        { text: "Oil", value: "is_oil" },
        { text: "Approved", value: "approved" },
        { text: "Edit Request", value: "edit_request" },
        { text: "Action", value: "actions", sortable: false, align: "end" },
      ],
      excel_headders: {
        "Date": "date",
        "Vehicle Number": "vehicle_id",
        "Employee": "user_id",
        "Project": "project_id",
        "Time Out": "time_out",
        "Time In": "time_in",
        "Meter reading Out": "meter_out",
        "Meter reading In": "meter_in",
        "No of km": "no_of_km",
        "Fuel": "fuel",
        "Fuel Last Filling (KM)": "fuel_meter_last",
        "Fuel Present Filling (KM)": "fuel_meter_present",
        "Total Litres": "fuel_no_of_liters",
        "No of Km Per L": "fuel_kms_per_liter",
        "Fuel Amount": "fuel_amount",
        "Oil Change Meter": "oil_meter_reading",
        "Oil Litres": "oil_no_of_liters",
        "Oil Amount": "oil_amount"
      },
      excel_history: [],
      history: [],
      show_history: [],
      snackbar: false,
      text: ``,
      timeout: 3000,
      user: [],
    };
  },
  methods: {
    filterDate() {
      this.$refs.dialog.save(this.dates);
      this.show_history = this.history.filter(
        (row) =>
          (row.date >= this.dates[0] && row.date <= this.dates[1]) ||
          (row.date <= this.dates[0] && row.date >= this.dates[1])
      );
    },
    getFiltered(data) {
      this.excel_history = data; //output the filtered items
    },
    more(item) {
      axios
        .get(this.$backend_url + "log-history-detail", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            log_id: item.id,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history = response.data.detail_history;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while receiving data.`;
        });
      this.detail_log = true;
    },
    approve(id) {
      axios
        .put(this.$backend_url + "log-approve", {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          log_id: id,
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history.open_for_edit = 0;
            this.detail_history.approved = 1;
            this.snackbar = true;
            this.text = `Log data approved`;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });
    },
    openEdit(id) {
      axios
        .put(this.$backend_url + "log-open-for-edit", {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          log_id: id,
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history.open_for_edit = 0;
            this.snackbar = true;
            this.text = `Log open for edit`;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });
    },
    changeView() {
      axios
        .get(this.$backend_url + "log-history", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            role_id: JSON.parse(localStorage.imso_user)["role"],
            view: this.seletedView,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.history = response.data.history;
            this.show_history = response.data.history;
          }
          this.nonet = false;
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.nonet = true;
          this.snackbar = true;
          this.text = `No Internet`;
        });
    },
    // cancelApprove(id) {
    //   axios
    //     .put(this.$backend_url+"log-approve-cancel", {
    //       remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
    //       email: JSON.parse(localStorage.imso_user)["email"],
    //       log_id: id,
    //     })
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.detail_history.approved = 0;
    //       }
    //     });
    // },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  mounted() {
    axios
      .get(this.$backend_url + "log-history", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          role_id: JSON.parse(localStorage.imso_user)["role"],
          view: this.seletedView,
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.history = response.data.history;
          this.show_history = response.data.history;
          this.vehicles = response.data.vehicles;
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });

    axios
      .get(this.$backend_url + "is-supervisor", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          role_id: JSON.parse(localStorage.imso_user)["role"],
          view: this.seletedView,
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.views.push({ text: "Given Employees", value: 2 });
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });

    if (JSON.parse(localStorage.imso_user)["role"] == 2) {
      this.views.push({ text: "Project Employees", value: 3 });
    }

    this.user = JSON.parse(localStorage.imso_user);
  },
};
</script>

<style>
.rch-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}
@media only screen and (max-width: 960px) {
  .rch-main {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .rch-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>